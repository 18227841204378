import React, { MouseEvent } from "react"
import { useNavigate } from "react-router-dom"
import { gql, useQuery, useReactiveVar } from "@apollo/client"
import { Loading } from "components/Loading"
import { getAge, JSTDate } from "contexts/dateUtils"
import options from "contexts/options.json"
import { FilterCheck, FilterInput } from "components/UIParts"
import { filterStates } from "graphql/RealmApolloProvider"
import { patientsDataQuery } from "./edit"

const heads = [
    { field: "isTemp", text: "仮登録" },
    { field: "patientId", text: "患者ID" },
    { field: "name", text: "氏名" },
    { field: "nameKana", text: "氏名カナ" },
    { field: "gender", text: "性別" },
    { field: "birthDate", text: "生年月日" },
    { field: "birthDate", text: "年齢" },
    { field: "tel", text: "電話番号" },
    { field: "mobileTel", text: "携帯番号" },
    { field: "email", text: "メール" },
]

const getQueryVariables = (values: KV) => {
    let output: KV ={ query: {} }
    if (values.isTemp !== undefined && values.isTemp !== null) output.query.isTemp = values.isTemp
    if (values.patientId) output.query.patientId = values.patientId
    if (values.name) output.query.name = values.name
    if (values.nameKana) output.query.nameKana = values.nameKana
    return output
}

export const List = () => {
    const navigate = useNavigate()
    const values: KV = useReactiveVar(filterStates)
    const names = values.patientList || { isTemp:false, patientId: "", name: "", nameKana: "" }
    console.log(getQueryVariables(names))
    const { loading, data } = useQuery(patientsDataQuery, { variables:getQueryVariables(names) })

    const setValue = (name: string, value: any) => {
        filterStates({ ...filterStates(), patientList: { ...names, [name]: value } })
    }

    const handleClick = (e:MouseEvent<HTMLButtonElement|HTMLTableRowElement>) => {
        e.preventDefault()
        e.stopPropagation()
        navigate(`/patient/edit/${e.currentTarget.dataset.id}`)
    }

    return <>
        <div className="relative h-full grid grid-rows-list">
            <div className="bg-theme-200 p-2 relative">
                <div className="flex flex-wrap justify-center items-center gap-2">
                    <FilterCheck label="仮登録のみ" name="isTemp" value={names.isTemp} setValue={setValue} />
                    <FilterInput label="患者ID" name="patientId" value={names.patientId} setValue={setValue} />
                    <FilterInput label="患者名" name="name" value={names.name} setValue={setValue} />
                    <FilterInput label="患者名カナ" name="nameKana" value={names.nameKana} setValue={setValue} />
                </div>
                <div className="absolute bottom-2 right-2">{data?.patients && `${data.patients.length} 件` }</div>
            </div>
            <div className="bg-white"></div>
            <div className="px-2 pb-2 h-full overflow-scroll flex flex-col z-0">
                {loading ? <Loading full/> : 
                    <table className="mx-auto border-b border-gray-200 sm:rounded-b-md text-center text-sm text-gray-500">
                        <thead>
                            <tr>
                                {heads.map((head, i) => (
                                    <th
                                        key={(head.field || "") + i}
                                        data-name={head.field}
                                        data-value={i}
                                        scope="col"
                                    >
                                        {head.text}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {data?.patients && data.patients.map((datum:KV) => (
                                    <tr key={datum._id} data-id={datum._id} className={`text-sm text-gray-900 cursor-pointer hover:bg-theme-50`} onClick={handleClick}>
                                        <td className={`${datum.isTemp ? 'text-white bg-red-300' : ''}`}>{datum.isTemp ? "仮登録" : ""}</td>
                                        <td>{datum.patientId}</td>
                                        <td>{datum.name}</td>
                                        <td>{datum.nameKana || ''}</td>
                                        <td>{datum.gender ? options.genderType[datum.gender] : ""}</td>
                                        <td>{datum.birthDate && JSTDate(new Date(datum.birthDate))}</td>
                                        <td>{datum.birthDate && getAge(new Date(datum.birthDate))}</td>
                                        <td>{datum.tel || ''}</td>
                                        <td>{datum.mobileTel || ''}</td>
                                        <td>{datum.email || ''}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    </>
}

export default List